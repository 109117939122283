import * as React from 'react';
import PageLayout from "src/components/layout/PageLayout";
import { Box, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import TeamsTable from 'src/components/settings/TeamsTable';
import DataIntegrations from 'src/components/settings/DataIntegrations';

const tabStyles = {
  color: 'rgba(0, 0, 0, 0.87)',
  '&.Mui-selected': {
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: 'rgba(107,33,168,.1)',
  },
  '&:hover': {
    backgroundColor: 'rgba(107,33,168,.1)',
  },
};

export default function Settings() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  return (
    <PageLayout>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
            value={value} 
            onChange={handleChange} 
            aria-label="settings tabs" 
            TabIndicatorProps={{
                style: {
                backgroundColor: 'rgba(99,38,161,1)', 
                },
          }}>
          <Tab 
            label="Teams"
            sx={tabStyles}
          />
          <Tab 
            label="Data Integrations"
            sx={tabStyles}
            disableRipple
          />
        </Tabs>
      </Box>

      <Box sx={{ p: 2 }}>
        {value === 0 && (
          <TeamsTable />
        )}
        {value === 1 && (
        <DataIntegrations />
        )}
      </Box>

    </PageLayout>
  );
}
