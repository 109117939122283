import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography, Grid, Card, CardContent, ButtonBase } from '@mui/material';
import { MetabaseDashboard } from '../utils/types';
import { DASHBOARDS } from '../utils/consts';
import { styled } from '@mui/material/styles';

const StyledCardContent = styled(CardContent)(({ theme }) => ({
   ['&.MuiCardContent-root:last-child']: {
    padding: theme.spacing(2),
   }
}));

function getDashboardById(id: number): { key: string, dashboard: typeof DASHBOARDS[keyof typeof DASHBOARDS] } | undefined {
    for (const key in DASHBOARDS) {
        const dashboard = DASHBOARDS[key as keyof typeof DASHBOARDS];
        if (dashboard.dashboard_id === id) {
            return { key, dashboard };
        }
    }
    return undefined;
}

export function RecentDashboards({ recentDashboards, userName }: { recentDashboards: MetabaseDashboard[], userName: string }) {
    const firstName = userName.split(' ')[0];

    return recentDashboards.length === 0 ?
        (
            <Box p={4}>
                <Typography variant="h5" mb={2}>{`Welcome, ${firstName}!`}</Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary', mt: 1, mb: 3 }}>Use the menu icon on the left to open the panel, then select a dashboard to get started.</Typography>
            </Box>
        )
        : (
            <Box p={4}>
                <Typography variant="h5" mb={2}>{`Hey there, ${firstName}!`}</Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary', mt: 1, mb: 3 }}>Pick up where you left off</Typography>
                <Grid container spacing={2}>
                    {recentDashboards.map(rd => {
                        const { key, dashboard } = getDashboardById(rd.id) ?? {};
                        if (!key || !dashboard) return null;

                        return (
                            <Grid item xs={12} sm={6} md={4} key={rd.id}>
                                <ButtonBase
                                    component="a"
                                    href={`/sponsor/dashboard/${key}/dashboard`}
                                    sx={{ width: '100%', display: 'block', borderRadius: 2 }}
                                    disableRipple
                                >
                                    <Card
                                        sx={{
                                            boxShadow: 'none',
                                            backgroundColor: 'rgba(107,33,168,.1)',
                                            borderRadius: 2,
                                            transition: 'background-color 0.3s',
                                            '&:hover': {
                                                backgroundColor: 'rgba(107,33,168,.2)',
                                            },
                                            '&:hover .icon, &:hover .text': {
                                                color: 'rgba(99,38,161,1)',
                                            },
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        
                                        <StyledCardContent sx={{ width: '100%'  }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}
                                            >
                                                <FontAwesomeIcon icon={dashboard.icon} className="icon text-sm h-4 text-gray-700" />
                                                <Typography variant="body1" ml={1} className="text">{dashboard.fullName}</Typography>
                                            </Box>
                                        </StyledCardContent>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        );
}