import { useParams } from 'react-router-dom';
import { DASHBOARDS } from '../../../../utils/consts';
import PageLayout from 'src/components/layout/PageLayout';
import DashboardComp from '../../../../components/Dashboard';

const Dashboard = () => {
	const { id } = useParams<{ id: string }>();

	const dashboardName = id as keyof typeof DASHBOARDS;
	const dashboardId = DASHBOARDS[dashboardName].dashboard_id;

    return <PageLayout>
		<div style={{height: '100%'}}> 
			<DashboardComp dashboardId={dashboardId} />
        </div>
	</PageLayout>
}

export default Dashboard;