import { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import config from '../amplifyconfiguration.json';
import { getUserSession } from '../api/user';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
} from '@mui/material';
import VerifyOtpPage from '../components/signin/VerifyOTP';

Amplify.configure(config);

const SignInPage = () => {
  const [username, setUsername] = useState('');
  const [showOtpPage, setShowOtpPage] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      const user = await getUserSession();
      if (user) {
        navigate('/sponsor/home');
      }
    };

    checkSession();
  }, [navigate]);

  const login = async () => {
    try {
        const lowercaseUsername = username.toLocaleLowerCase();
        setLoading(true);
        const { nextStep } = await signIn({
        username: lowercaseUsername,
        password: lowercaseUsername,
        options: {
            authFlowType: 'CUSTOM_WITH_SRP',
        },
        });

        if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
            setLoading(false);
            setShowOtpPage(true); // Show the OTP verification page
        }
        else {
            throw new Error('Something went wrong with sign in');
        }
    } catch (error) {
        setLoading(false);
      console.error('Error signing in', error);
      setError('Invalid email. Please check your spelling and try again.');
    }
  };

  if (showOtpPage) {
    return <VerifyOtpPage username={username} confirmSignIn={confirmSignIn} />;
  }

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        display="flex"
        flexDirection="column"
        justifyContent="center"
        xs={12}
      >
        <Container maxWidth="xs">
          <Box display="flex" justifyContent="center" mb={3}>
            <img
              src={`/${process.env.REACT_APP_CLIENT_LOGO}`}
              alt="Miracle Logo"
            />
          </Box>
          <Typography variant="h5" align="center" sx={{ fontWeight: 700 }}>
            Welcome to Miracle
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ color: 'text.secondary', mt: 1, mb: 3 }}
          >
            Enter your email below and we'll send you a one-time passcode to log in password-free.
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="you@email.com"
            type="email"
            value={username}
            onChange={(e) => {
                setError(''); 
                setUsername(e.target.value); 
            }}
            sx={{ mb: 3 }}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={login}
            sx={{
              py: 1.5,
              backgroundColor: '#1A1A1A',
              ':hover': { backgroundColor: '#333333' },
            }}
            disabled={loading}
          >
            Sign in
          </Button>
          <Box display="flex" justifyContent="center" mt={2} sx={{ height: '24px' }}>
            {error && <Typography variant="body1" align="center" sx={{ color: 'red' }}>{error}</Typography>}
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default SignInPage;