import sign from 'jwt-encode';
import { UserAttributes } from '../utils/types';
import { getIdToken } from './user';

export async function getJWT(secret: string): Promise<string> {
    const idToken = await getIdToken();

	const props = {
		sub: idToken?.payload.sub,
		exp: idToken?.payload.exp,
	}
	const token = sign(props, secret);
	return token;
}

// /**
//  * Gets a JWT for a dashboard
//  * @param user_attributes 
//  * @param secret 
//  * @returns user_jwt
//  */
const getDashboardJWT = (user_attributes: UserAttributes, secret: string): string => {
	const user_jwt = sign({	
        name: user_attributes.name,
		email: user_attributes.email,
		role: user_attributes.role,
	}, secret || "INVALID_SECRET", { expiresIn: '8h' })
	return user_jwt;
}

/**
 * Signs a JWT with the given payload and secret
 * @param payload 
 * @param secret 
 * @returns jwt 
 */
const signJWT = (payload: any, secret: string): string => {
    return sign(payload, secret);
}

export { getDashboardJWT, signJWT }