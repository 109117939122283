import { ReactNode, useState } from 'react';
import Header from './Header';
import CollapsibleSidebar from './CollapsibleSidebar';
import { Box } from '@mui/material';

interface PageLayoutProps {
    children: ReactNode;
}

const sidebarCollapsedWidth = '56px'; // Width when sidebar is collapsed
const sidebarExpandedWidth = '240px'; // Width when sidebar is expanded

const sidebarContainerStyle = {
    transition: 'width 0.3s ease', // Smooth transition for sidebar width
    overflowX: 'hidden', // Hide any overflowing content
};

const sidebarBorderStyle = {
    borderRight: '1px solid #e0e0e0', // Light grey border on the right side of the sidebar
};

const headerBorderStyle = {
    width: '100%',
    borderBottom: '1px solid #e0e0e0', // Light grey border at the bottom of the header
    background: 'white',
    height: '50px',
};

const PageLayout = ({ children }: PageLayoutProps) => {

    const [open, setOpen] = useState(false);

    return (
        <Box display="flex" justifyContent="space-between" flexDirection="column" height="100vh">
            <Box position="fixed" top={0} left={0} zIndex={1100} sx={headerBorderStyle}>
                <Header />
            </Box>

            <Box display="flex" flexGrow={1} mt="50px"> {/* Adjust margin-top based on header height */}
                <Box
                    width={sidebarCollapsedWidth}
                    height="100%"
                    sx={{ ...sidebarContainerStyle, ...sidebarBorderStyle }}
                    onMouseEnter={(e) => { setOpen(true); e.currentTarget.style.width = sidebarExpandedWidth }}
                    onMouseLeave={(e) => { setOpen(false); e.currentTarget.style.width = sidebarCollapsedWidth }}
                >
                    <CollapsibleSidebar open={open} setOpen={setOpen} />
                </Box>
                <Box
                    component="main"
                    flexGrow={1}
                    sx={{
                        transition: 'margin-left 0.3s ease',
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export default PageLayout;
