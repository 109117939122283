import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { useUserAttributes } from 'src/UserContext';
import { get } from 'lodash';
import styled from '@emotion/styled';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    ['&.MuiToolbar-root']: {
        minHeight: '50px',
    },
}));

const Header = () => {
    const { attributes } = useUserAttributes();
    const userEmail = get(attributes, 'email', '');
    return (
        <AppBar position="static" color="transparent" elevation={0}>
            <StyledToolbar>
                {/* Left Side - Logo */}
                <Box display="flex" alignItems="center" flexGrow={1}>
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <img src={`/${process.env.REACT_APP_CLIENT_LOGO}`} alt="Logo" width="70px" />
                    </Link>
                </Box>

                {/* Right Side - User Info */}
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                    <Typography variant="body2" fontWeight="bold">
                        {process.env.REACT_APP_CLIENT_NAME}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                        {userEmail}
                    </Typography>
                </Box>
            </StyledToolbar>
        </AppBar>
    );
};

export default Header;