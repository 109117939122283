import { MOBILE_WIDTH } from "../utils/consts"
import { useState, useEffect } from "react"

export function MetabaseIFrame({ staticURL, authURL }: { staticURL: string, authURL: string }) {
	const [iframeUrl, setIframeUrl] = useState<string | null>(null)
	useEffect(() => {
		if (window.innerWidth <= MOBILE_WIDTH) {
			setIframeUrl(staticURL)
		} else {
			setIframeUrl(authURL)
		}
	}, [staticURL, authURL])

	if (!iframeUrl) return <div className="h-full w-full flex items-center justify-center text-4xl">
		Loading...
	</div>
	return <iframe
		src={iframeUrl}
		frameBorder="0"
		width="100%"
		height="100%"
	/>
}