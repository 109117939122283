import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Box,
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { INTEGRATIONS_DASHBOARDS } from '../../utils/consts';
import Dashboard from '../Dashboard';

interface Study {
  name: string;
  dashboard_id: number;
}

interface Integration {
  name: string;
  icon: string;
  studies: Study[];
}

interface CollapsibleRowProps {
  integration: Integration;
}

function StudyCollapsibleRow({ study }: { study: Study }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        onClick={() => setOpen(!open)}
        style={{ cursor: 'pointer' }}
      >
        <TableCell>{study.name}</TableCell>
        <TableCell align="right">
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} height={400}>
              <Dashboard dashboardId={study.dashboard_id} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function CollapsibleRow({ integration }: CollapsibleRowProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        onClick={() => setOpen(!open)}
        style={{ cursor: 'pointer' }}
      >
        <TableCell>
          <img src={integration.icon} width={100} alt={integration.name} />
        </TableCell>
        <TableCell align="right">
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table>
                <TableBody>
                  {integration.studies.map((study) => (
                    <StudyCollapsibleRow key={study.dashboard_id} study={study} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const DataIntegrations = () => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Integration</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(INTEGRATIONS_DASHBOARDS).map((integration) => (
            <CollapsibleRow key={integration.name} integration={integration} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DataIntegrations;