import { getJWT } from './jwt';
import { DASHBOARDS } from 'src/utils/consts';

const MAIN_BACKEND_URL = process.env.REACT_APP_MAIN_BACKEND_URL;

export type MetabaseDashboard = {
	last_seen: string;
	id: number;
	name: string;
	description: string | null;
}

export type User = {
	email: string;
	name: string;
	role: string;
  title: string;
}


// Function to map API response to User array
const mapToUsers = (apiResponse: any): User[] => {
    return apiResponse.users.map((user: any) => {
      const emailAttr = user.Attributes.find((attr: any) => attr.Name === "email");
      const nameAttr = user.Attributes.find((attr: any) => attr.Name === "name");
      const roleAttr = user.Attributes.find((attr: any) => attr.Name === "custom:role");
      const titleAttr = user.Attributes.find((attr: any) => attr.Name === "custom:title");
  
      return {
        email: emailAttr ? emailAttr.Value : '',
        name: nameAttr ? nameAttr.Value : '',
        role: roleAttr ? roleAttr.Value : '',
        title: titleAttr ? titleAttr.Value : ''
      };
    });
  };

export async function getMetabaseUserRecentDashboards(email: string): Promise<MetabaseDashboard[]> {
    try {
		const user_jwt = await getJWT(process.env.REACT_APP_SECRET || "INVALID_SECRET")
        const response = await fetch(`${MAIN_BACKEND_URL}/new_auth/recent_dashboards`, {
            headers: {
                "Content-Type": "application/json",
                accept: "application/json",
                authorization: `Bearer ${user_jwt}`,
            },
            body: JSON.stringify({email, filtered_dashboards: Object.values(DASHBOARDS).map((d) => String(d.dashboard_id))}),
            method: "POST"
        });

        if (!response.ok) {
            console.log(`Error fetching recent dashboards: ${response.statusText}`);
			return [];
        } else {
			const data = await response.json();
			const timeSortedData = [...data].sort((a: MetabaseDashboard, b: MetabaseDashboard) => new Date(b.last_seen).getTime() - new Date(a.last_seen).getTime());
			return timeSortedData;
		}
    } catch (error) {
		console.log('Failed to fetch recent dashboards');
		return [];
    }
}

export async function getAllUsers(): Promise<User[]> {
  try {
      const user_jwt = await getJWT(process.env.REACT_APP_SECRET || "INVALID_SECRET");
      const response = await fetch(`${MAIN_BACKEND_URL}/new_auth/list-users-in-pool`, {
          headers: {
              "Content-Type": "application/json",
              accept: "application/json",
              authorization: `Bearer ${user_jwt}`
          },
          body: JSON.stringify({
            client_name: process.env.REACT_APP_CLIENT_NAME,
            env_name: process.env.REACT_APP_ENVIRONMENT
          }),
          method: "POST"
      });
      const data = await response.json();
      const users = mapToUsers(data);
      const sortedUsers = users.sort((a, b) => a.name.localeCompare(b.name));
      return sortedUsers;
  } catch (error) {
      console.log('Failed to fetch users', error);
      return [];
  }
}