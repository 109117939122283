import { TextField, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, Typography } from "@mui/material";
import { Add as AddIcon, Search as SearchIcon, Close as CloseIcon, MoreVert as MoreVertIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useState, useEffect } from "react";
import { getAllUsers, User } from "src/api/mbe";
import axios from 'axios';
import { errorToast, successToast } from 'src/utils/helperFns';
import { get } from 'lodash';

export default function Settings() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState('all-users');
  const [users, setUsers] = useState<User[]>([]);
  const [open, setOpen] = useState(false); // State for add user dialog open/close
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // Anchor for the menu
  const [selectedUser, setSelectedUser] = useState<User | null>(null); // The user selected in the menu
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false); // State for delete confirmation dialog
  const [roleChangeConfirmOpen, setRoleChangeConfirmOpen] = useState(false); // State for role change confirmation dialog
  const [pendingRoleChange, setPendingRoleChange] = useState<{ email: string, role: string } | null>(null); // State for the role change
  const [editDialogOpen, setEditDialogOpen] = useState(false); // State for edit dialog open/close
  const [editUser, setEditUser] = useState<User | null>(null); // State for the user being edited
  const [emailError, setEmailError] = useState(false);
  
  const [newUser, setNewUser] = useState({ firstname: '', lastname: '', email: '', role: '', title: '' });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleRoleChangeRequest = (event: SelectChangeEvent, email: string) => {
    const updatedRole = event.target.value;
    setPendingRoleChange({ email, role: updatedRole });
    setRoleChangeConfirmOpen(true);
  };

  const confirmRoleChange = async () => {
    if (pendingRoleChange) {
      try {
        await axios.put(`${process.env.REACT_APP_MAIN_BACKEND_URL}/new_auth/update-role`, {
          username: pendingRoleChange.email,
          role: pendingRoleChange.role, 
          client_name: process.env.REACT_APP_CLIENT_NAME,
          env_name: process.env.REACT_APP_ENVIRONMENT
        });
        const userResp: User[] = await getAllUsers();
        setUsers(userResp);
        successToast('User role updated successfully!');
      } catch (error) {
        console.error("There was an error updating the user's role!", error);
        errorToast('There was an error updating the user\'s role!');
      } finally {
        setRoleChangeConfirmOpen(false);
        setPendingRoleChange(null);
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddUser = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_MAIN_BACKEND_URL}/new_auth/signup`, {
        name: newUser.firstname + " " + newUser.lastname,
        username: newUser.email,
        role: newUser.role,
        title: newUser.title,
        client_name: process.env.REACT_APP_CLIENT_NAME,
        env_name: process.env.REACT_APP_ENVIRONMENT
      });
      setOpen(false);
      const userResp: User[] = await getAllUsers();
      setUsers(userResp);
      setNewUser({ firstname: '', lastname: '', email: '', role: '', title: '' });
      successToast('User created successfully!');
    } catch (error) {
      console.error("There was an error creating the user!", error);
      let errMessage = '';
      if (get(error, 'response.data.detail') == 'Username already exists.') {
        errMessage = 'Username already exists.';
      } else {
        errMessage = "There was an error creating the user!";
      }
      errorToast(errMessage);
      setOpen(false);
    }
  };
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validateEmailFormat = (e: React.FocusEvent<HTMLInputElement>) => {
    const email = e.target.value;
    if (!emailRegex.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };
  const isFormValid = () => {
    return (
      newUser.firstname &&
      newUser.lastname &&
      newUser.email &&
      newUser.role &&
      !emailError
    );
  };


  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, user: User) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditUserClick = (user: User) => {
    setEditUser(user);
    setEditDialogOpen(true);
  };

  const handleEditUser = () => {
    if (selectedUser) {
      handleEditUserClick(selectedUser);
    }
    handleMenuClose();
  };

  const handleUpdateUser = async () => {
    if (editUser) {
      try {
        await axios.put(`${process.env.REACT_APP_MAIN_BACKEND_URL}/new_auth/update-user-attributes`, {
          username: editUser.email,
          name: editUser.name,
          role: editUser.role,
          title: editUser.title, 
          client_name: process.env.REACT_APP_CLIENT_NAME,
          env_name: process.env.REACT_APP_ENVIRONMENT
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            // Exclude or minimize cookies and other unnecessary headers
          }
        });
        const userResp = await getAllUsers();
        setUsers(userResp);
        setEditDialogOpen(false);
        setEditUser(null);
        successToast('User updated successfully!');
      } catch (error) {
        console.error("There was an error updating the user's information!", error);
        errorToast('There was an error updating the user\'s information!');
        setOpen(false);
      }
    }
  };
  

  const handleDeleteUser = async () => {
    if (selectedUser) {
      try {
        await axios.delete(`${process.env.REACT_APP_MAIN_BACKEND_URL}/new_auth/delete-account`, {
          params: { 
            username: selectedUser.email, 
            client_name: process.env.REACT_APP_CLIENT_NAME, 
            env_name: process.env.REACT_APP_ENVIRONMENT 
          }
        });
        const userResp: User[] = await getAllUsers();
        setUsers(userResp);
        successToast('User deleted successfully!');
      } catch (error) {
        console.error("There was an error deleting the user!", error);
        errorToast('There was an error deleting the user!');
        setOpen(false);
      }
    }
    setDeleteConfirmOpen(false);
  };

  const handleDeleteClick = () => {
    setDeleteConfirmOpen(true);
    handleMenuClose();
  };

  const handleConfirmDeleteClose = () => {
    setDeleteConfirmOpen(false);
  };

  const [openRoles, setOpenRoles] = useState<{ [key: string]: boolean }>({});

  const handleRoleOpen = (email: string) => {
    setOpenRoles(prev => ({ ...prev, [email]: true }));
  };

  const handleRoleClose = (email: string) => {
    setOpenRoles(prev => ({ ...prev, [email]: false }));
  };

  useEffect(() => {
    const fetchUsers = async () => {
      let userResp: User[] = await getAllUsers();

      if (searchTerm) {
        userResp = userResp.filter(user => 
          user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      setUsers(userResp);
    }
    fetchUsers();
  }, [selectedRole, searchTerm]);

  return (
    <>
      <>
        <Grid container spacing={1} alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  placeholder="Search users"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    backgroundColor: 'white',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <FormControl size="small" fullWidth>
                  <Select
                    value={selectedRole}
                    onChange={(event) => setSelectedRole(event.target.value)}
                    displayEmpty
                    sx={{
                      backgroundColor: 'white',
                      '& .MuiSelect-select': {
                        padding: '8px 32px 8px 8px',
                      },
                    }}
                  >
                    <MenuItem value="all-users">All Users ({users.length})</MenuItem>
                    <MenuItem value="admin">Admin ({users.filter(user => user.role === 'admin').length})</MenuItem>
                    <MenuItem value="operations">Operations ({users.filter(user => user.role === 'operations').length})</MenuItem>
                    <MenuItem value="medical">Medical ({users.filter(user => user.role === 'medical').length})</MenuItem>
                    <MenuItem value="leadership">Leadership ({users.filter(user => user.role === 'leadership').length})</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleClickOpen}  // Opens the dialog
                  sx={{
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                    padding: '6px 12px',
                    fontSize: '0.875rem',
                  }}
                >
                  Add User
                </Button>
              </Grid>
            </Grid>

            {/* Dialog for adding user */}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
              <DialogTitle>
                Add person
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="firstname"
                  label="First Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={newUser.firstname}
                  onChange={(e) => setNewUser({ ...newUser, firstname: e.target.value })}
                  required
                />
                <TextField
                  margin="dense"
                  id="lastname"
                  label="Last Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={newUser.lastname}
                  onChange={(e) => setNewUser({ ...newUser, lastname: e.target.value })}
                  required
                />
                <TextField
                  margin="dense"
                  id="email"
                  label="Email"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={newUser.email}
                  onChange={(e) => {
                    setEmailError(false)
                    setNewUser({ ...newUser, email: e.target.value });
                  }}
                  onBlur={validateEmailFormat}
                  error={emailError}
                  helperText={emailError ? "Invalid email format" : ""}
                  required
                />
                <TextField
                  margin="dense"
                  id="title"
                  label="Title"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={newUser.title}
                  onChange={(e) => setNewUser({ ...newUser, title: e.target.value })}
                />
                <FormControl fullWidth margin="dense">
                  <InputLabel id="group-select-label" required>Role</InputLabel>
                  <Select
                    labelId="group-select-label"
                    id="group-select"
                    label="Role"
                    value={newUser.role}
                    onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
                    required
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="operations">Operations</MenuItem>
                    <MenuItem value="medical">Medical</MenuItem>
                    <MenuItem value="leadership">Leadership</MenuItem>
                  </Select>
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAddUser} variant="contained" color="primary" disabled={!isFormValid()}>
                  Add
                </Button>
              </DialogActions>
            </Dialog>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="user table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>NAME</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>EMAIL</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>ROLE</TableCell>
                    <TableCell>{" "}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(selectedRole === 'all-users' ? users : users.filter(user => user.role === selectedRole)).map((user) => (
                    <TableRow key={user.email}>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          <InputLabel id={`role-label-${user.email}`}>Role</InputLabel>
                          <Select
                            labelId={`role-label-${user.email}`}
                            id={`role-select-${user.email}`}
                            open={openRoles[user.email] || false}
                            onClose={() => handleRoleClose(user.email)}
                            onOpen={() => handleRoleOpen(user.email)}
                            value={user.role}
                            label="Role"
                            onChange={(event) => handleRoleChangeRequest(event, user.email)}
                          >
                            <MenuItem value="admin">Admin</MenuItem>
                            <MenuItem value="operations">Operations</MenuItem>
                            <MenuItem value="medical">Medical</MenuItem>
                            <MenuItem value="leadership">Leadership</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={(e) => handleMenuClick(e, user)} aria-label="more">
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          PaperProps={{
                            style: {
                              boxShadow: 'none', 
                              border: '1px solid #ccc',
                              borderRadius: '4px'
                            },
                          }}
                        >
                          <MenuItem onClick={handleEditUser}>
                            <EditIcon />
                            <Typography variant="body1" marginLeft={1}>Edit</Typography>
                          </MenuItem>
                          <MenuItem onClick={handleDeleteClick}>
                            <DeleteIcon />
                            <Typography variant="body1" marginLeft={1}>Delete</Typography>
                          </MenuItem>
                      </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleConfirmDeleteClose}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description"
      >
        <DialogTitle id="confirm-delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this user?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose}>Cancel</Button>
          <Button onClick={handleDeleteUser} color="primary" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Role Change Confirmation Dialog */}
      <Dialog
        open={roleChangeConfirmOpen}
        onClose={() => setRoleChangeConfirmOpen(false)}
        aria-labelledby="confirm-role-change-dialog-title"
        aria-describedby="confirm-role-change-dialog-description"
      >
        <DialogTitle id="confirm-role-change-dialog-title">Confirm Role Change</DialogTitle>
        <DialogContent>
          Are you sure you want to change this user's role to {pendingRoleChange?.role}?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRoleChangeConfirmOpen(false)}>Cancel</Button>
          <Button onClick={confirmRoleChange} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit User Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="edit-firstname"
            label="First Name"
            type="text"
            fullWidth
            variant="outlined"
            value={editUser?.name.split(" ")[0] || ''}
            onChange={(e) =>
              setEditUser((prev) => 
                prev 
                ? { ...prev, name: `${e.target.value} ${prev.name.split(" ")[1]}` } 
                : null
              )
            }
          />
          <TextField
            margin="dense"
            id="edit-lastname"
            label="Last Name"
            type="text"
            fullWidth
            variant="outlined"
            value={editUser?.name.split(" ")[1] || ''}
            onChange={(e) =>
              setEditUser((prev) => 
                prev 
                ? { ...prev, name: `${prev.name.split(" ")[0]} ${e.target.value}` } 
                : null
              )
            }
          />
          <TextField
            margin="dense"
            id="edit-title"
            label="Title"
            type="text"
            fullWidth
            variant="outlined"
            value={editUser?.title || ''}
            onChange={(e) =>
              setEditUser((prev) => 
                prev 
                ? { ...prev, title: e.target.value } 
                : null
              )
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="edit-role-label">Role</InputLabel>
            <Select
              labelId="edit-role-label"
              id="edit-role-select"
              label="Role"
              value={editUser?.role || ''}
              onChange={(e) =>
                setEditUser((prev) => 
                  prev 
                  ? { ...prev, role: e.target.value } 
                  : null
                )
              }
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="operations">Operations</MenuItem>
              <MenuItem value="medical">Medical</MenuItem>
              <MenuItem value="leadership">Leadership</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleUpdateUser}
            color="primary"
            variant="contained"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
