import PageLayout from 'src/components/layout/PageLayout';
import { USER_METRICS_DASHBOARD_ID } from '../../utils/consts';
import Dashboard from "src/components/Dashboard";

const UserMetrics = () => {
    return <PageLayout>
        <div style={{height: '100%'}}> 
            <Dashboard dashboardId={USER_METRICS_DASHBOARD_ID} />
        </div>
  </PageLayout>
}

export default UserMetrics;