import { useUserAttributes } from 'src/UserContext';
import { RecentDashboards } from 'src/components/RecentDashboards';
import PageLayout from 'src/components/layout/PageLayout';
import { get, isNull } from 'lodash';
import { useState, useEffect } from 'react';
import { getMetabaseUserRecentDashboards } from 'src/api/mbe';
import { MetabaseDashboard } from 'src/api/mbe';
import { LinearProgress, Box } from '@mui/material';

const Home = () => {
  const { attributes } = useUserAttributes();
  const [recentDashboards, setRecentDashboards] = useState<MetabaseDashboard[] | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const userName = get(attributes, 'name', '');

  useEffect(() => {
    const fetchDashboards = async () => {
      getMetabaseUserRecentDashboards(attributes?.email || '').then((dashboards) => {
        setRecentDashboards(dashboards || []);
        setIsLoading(false);
      });
    };
    fetchDashboards();
  }, []);

  useEffect(() => {
    if (isLoading) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 0;
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isLoading]);

  return !isLoading && !isNull(recentDashboards) ? (
    <PageLayout>
      <RecentDashboards recentDashboards={recentDashboards} userName={userName} />
    </PageLayout>
  ) : (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
};

export default Home;