import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
} from '@mui/material';

const VerifyOtpPage = ({ username, confirmSignIn }: { username: string, confirmSignIn: any }) => {
  const [otp, setOtp] = useState(new Array(6).fill('')); // Assuming a 6-digit OTP
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePaste = (event: any) => {
    const pastedData = event.clipboardData.getData('text');
    const otpArray = pastedData.slice(0, 6).split('');

    setOtp(otpArray);

    // Focus the last input field
    setTimeout(() => {
      document.getElementById(`otp-input-${otpArray.length - 1}`)?.focus();
    }, 0);
    event.preventDefault();
  };

  const handleChange = (element: any, index: number) => {
    setError('');
    const value = element.value;
    const newOtp = [...otp];
    newOtp[index] = value.slice(-1); // Get the last character in case of multiple inputs

    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      // Move to the next input field when typing a single digit
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }
  };

  const handleKeyDown = (event: any, index: number) => {
    if (event.key === 'Backspace' && otp[index] === '') {
      // Move to the previous input field when pressing Backspace on an empty box
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`)?.focus();
      }
    }
  };

  const verifyOtp = async () => {
    try {
      setError('');
      setLoading(true);
      const otpValue = otp.join('');
      const cognitoUser = await confirmSignIn({ challengeResponse: otpValue });
      if (cognitoUser.isSignedIn === true) {
        setLoading(false);
        navigate('/sponsor/home');
      } else if (cognitoUser.isSignedIn === false) {
        throw new Error('Invalid OTP. Please try again.');
      }
    } catch (error: any) {
      setLoading(false);
      if (error.message === 'Incorrect username or password.' || error.message === 'Invalid session for the user.') {
        let countdown = 5;
        const countdownInterval = setInterval(() => {
          setError(`Max attempts reached. Redirecting to signin page in ${countdown}...`);
          countdown -= 1;
          if (countdown === 0) {
            clearInterval(countdownInterval);
            window.location.href = '/';
          }
        }, 1000);
      } else {
        setError(error.message);
      }
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        display="flex"
        flexDirection="column"
        justifyContent="center"
        xs={12}
      >
        <Container maxWidth="xs">
          <Box display="flex" justifyContent="center" mb={3}>
            <img
              src={`/${process.env.REACT_APP_CLIENT_LOGO}`}
              alt="Miracle Logo"
            />
          </Box>
          <Typography variant="h5" align="center" sx={{ fontWeight: 700 }}>
            Verify your email
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ color: 'text.secondary', mt: 1, mb: 3 }}
          >
            A one-time passcode should be arriving soon to {username}. Please enter that code here.
          </Typography>
          <Box display="flex" justifyContent="center" mb={3}>
            {otp.map((data, index) => (
              <TextField
                key={index}
                id={`otp-input-${index}`}
                sx={{ textAlign: 'center', mx: 1 }}
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: 'center' },
                }}
                variant="outlined"
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={handlePaste}
              />
            ))}
          </Box>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={verifyOtp}
            sx={{
              py: 1.5,
              backgroundColor: '#1A1A1A',
              ':hover': { backgroundColor: '#333333' },
            }}
            disabled={loading}
          >
            Verify
          </Button>
          <Box display="flex" justifyContent="center" mt={2} sx={{ height: '24px' }}>
            {error && <Typography variant="body1" align="center" sx={{ color: 'red' }}>{error}</Typography>}
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default VerifyOtpPage;