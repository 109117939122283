import React, { useState, useEffect, useMemo } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Box, Collapse } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useSignOut } from 'src/api/user';
import { STUDIES, DASHBOARDS } from 'src/utils/consts';
import { useUserAttributes } from 'src/UserContext';
import { getAccessibleDashboards } from 'src/utils/dashboard_utils';
import { get } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';

interface CollapsibleSidebarProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

// Define the hover and selected styles in a reusable sx object
const listItemStyles = {
    height: '50px',
    '&.Mui-selected': {
        backgroundColor: 'rgba(107,33,168,.1)',
        '&:hover': {
            backgroundColor: 'rgba(107,33,168,.2)',
        },
    },
    '&:hover': {
        backgroundColor: 'rgba(107,33,168,.1)',
    },
};

const CollapsibleSidebar: React.FC<CollapsibleSidebarProps> = ({ open, setOpen }) => {
    const [subMenuItemsOpen, setSubMenuItemsOpen] = useState<{ [key: string]: boolean }>({});
    const [selectedPage, setSelectedPage] = useState<string>('');
    const { signOut } = useSignOut();
    const { attributes } = useUserAttributes();
    const navigate = useNavigate();
    const location = useLocation();
    const [textVisible, setTextVisible] = useState(false);

    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => setTextVisible(true), 300); // Adjust to match the transition duration
            return () => clearTimeout(timer);
        } else {
            setTextVisible(false);
        }
    }, [open]);

    const accessibleDashboardKeys = useMemo(
        () => getAccessibleDashboards(get(attributes, 'role', '')),
        [attributes]
    );

    const accessibleDashboards = useMemo(
        () => Object.fromEntries(
            Object.entries(DASHBOARDS).filter(([key, data]) => accessibleDashboardKeys.includes(key))
        ),
        [accessibleDashboardKeys]
    );

    useEffect(() => {
        const pathParts = location.pathname.split('/');
        if (pathParts.includes('dashboard')) {
            const currentDashboardKey = pathParts[3];
            if (selectedPage !== currentDashboardKey) {
                setSelectedPage(currentDashboardKey);
            }

            const currentStudy = accessibleDashboards[currentDashboardKey]?.study;
            if (currentStudy) {
                setSubMenuItemsOpen({ [currentStudy]: true }); // Collapse all studies except the one containing the selected dashboard
            }
        } else if (location.pathname === '/sponsor/home' && selectedPage !== 'home') {
            setSelectedPage('home');
            setSubMenuItemsOpen({});
        } else if (location.pathname === '/sponsor/settings' && selectedPage !== 'settings') {
            setSelectedPage('settings');
            setSubMenuItemsOpen({});
        } else if (selectedPage !== '') {
            setSelectedPage(''); // Reset if none match
            setSubMenuItemsOpen({});
        }
    }, [location.pathname]);

    const toggleSubMenu = (study: string) => {
        setSubMenuItemsOpen((prev) => ({
            ...prev,
            [study]: !prev[study],
        }));
    };

    const handleNavigation = (path: string, pageKey?: string, study?: string) => {
        if (pageKey) {
            setSelectedPage(pageKey);
            setSubMenuItemsOpen({ [study!]: true }); // Collapse all other studies and expand the current one
        }
        navigate(path);
    };

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
            <List disablePadding>
                <ListItem
                    button
                    onClick={() => handleNavigation('/sponsor/home', 'home')}
                    selected={selectedPage === 'home'}
                    sx={listItemStyles}
                >
                    <ListItemIcon>
                        <HomeIcon fontSize="small" />
                    </ListItemIcon>
                    {textVisible && <ListItemText primary="Home" />}
                </ListItem>

                {STUDIES.map((study) => (
                    <React.Fragment key={study}>
                        <ListItem button onClick={() => toggleSubMenu(study)} sx={listItemStyles}>
                            <ListItemIcon>
                                {subMenuItemsOpen[study] ? <FolderOpenIcon fontSize="small" /> : <FolderIcon fontSize="small" />}
                            </ListItemIcon>
                            {textVisible && (
                                <>
                                    <ListItemText primary={study} />
                                    <ExpandMoreIcon fontSize="small" />
                                </>
                            )}
                        </ListItem>
                        <Collapse in={subMenuItemsOpen[study]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {Object.entries(accessibleDashboards)
                                    .filter(([key, data]) => data.study === study)
                                    .map(([key, data]) => (
                                        <ListItem
                                            button
                                            key={key}
                                            sx={{ ...listItemStyles }}
                                            disableRipple
                                            selected={selectedPage === key}
                                            onClick={() => handleNavigation(`/sponsor/dashboard/${key}/dashboard`, key, study)}
                                        >
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={data.icon} />
                                            </ListItemIcon>
                                            {textVisible && <ListItemText primary={data.name} />}
                                        </ListItem>
                                    ))}
                            </List>
                        </Collapse>
                    </React.Fragment>
                ))}
            </List>

            <List sx={{ marginTop: 'auto' }} disablePadding>
                {get(attributes, 'role', '') === 'admin' && (
                    <>
                        <ListItem 
                            button
                            onClick={() => handleNavigation('/sponsor/user_metrics', 'user_metrics')}
                            selected={selectedPage === 'user_metrics'}
                            sx={listItemStyles}
                        >
                            <ListItemIcon>
                                <BarChartIcon />
                            </ListItemIcon>
                            {textVisible && <ListItemText primary="User Metrics" />}
                        </ListItem>
                        <ListItem
                            button
                            onClick={() => handleNavigation('/sponsor/settings', 'settings')}
                            selected={selectedPage === 'settings'}
                            sx={listItemStyles}
                        >
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            {textVisible && <ListItemText primary="Settings" />}
                        </ListItem>
                    </>
                )}
                <ListItem button onClick={signOut} sx={listItemStyles}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    {textVisible && <ListItemText primary="Sign out" />}
                </ListItem>
            </List>
        </Box>
    );
};

export default CollapsibleSidebar;