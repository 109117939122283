import { MetabaseIFrame } from "./MetabaseIFrame";
import { useUserAttributes } from '../UserContext';
import { getDashboardJWT, signJWT } from '../api/jwt';
import { UserAttributes } from '../utils/types';

const Dashboard = ({dashboardId}: {dashboardId: number}) => {
	const { attributes } = useUserAttributes();

	// Load Metabase Dashboard
	const user_jwt = getDashboardJWT(attributes as UserAttributes, process.env.REACT_APP_SECRET || "INVALID_SECRET");

    const payload = {
		resource: { dashboard: dashboardId },
		params: {},
		exp: Math.round(Date.now() / 1000) + (8 * 60 * 60) // 8 hour expiration
	};

	const secretKey = process.env.REACT_APP_METABASE_SECRET_KEY;
	if (!secretKey) {
		throw new Error("METABASE_SECRET_KEY is not defined");
	}

	const token = signJWT(payload, secretKey);
	const staticUrl = `${process.env.REACT_APP_METABASE_SITE_URL}/embed/dashboard/${token}#bordered=true&titled=true`;
	const dynamicUrl = `${process.env.REACT_APP_NEXT_PUBLIC_AUTH_URL}/oauth/metabase?dashboard_id=${dashboardId}&user_jwt=${user_jwt}`

    return <MetabaseIFrame
        staticURL={staticUrl}
        authURL={dynamicUrl}
    />

}

export default Dashboard;