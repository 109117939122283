import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignInPage from './pages/signin';
import Home from './pages/sponsor/home';
import Dashboard from './pages/sponsor/dashboard/[id]/dashboard';
import UserMetrics from './pages/sponsor/user_metrics';
import Settings from './pages/sponsor/settings';
import { UserProvider } from 'src/UserContext';
import AuthGuard from './AuthGuard';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
    <ToastContainer />
    <Router>
      <Routes>
        <Route path="/" element={<SignInPage />} />
        <Route
          path="/sponsor/*"
          element={
            <AuthGuard>
              <UserProvider>
                <Routes>
                  <Route path="home" element={<Home />} />
                  <Route path="dashboard/:id/dashboard" element={<Dashboard />} />
                  <Route path="user_metrics" element={<UserMetrics />} />
                  <Route path="settings" element={<Settings />} />
                </Routes>
              </UserProvider>
            </AuthGuard>
          }
        />
      </Routes>
    </Router>
    </>
  );
}

export default App;