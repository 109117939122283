import { DASHBOARDS, ROLES } from "../utils/consts";

/**
 * Get the list of dashboards accessible to each user based on role
 * @param role 
 * @returns list of dashboards accessible to each user based on role
 */
const getAccessibleDashboards = (role: String) => {
    const dashboards = Object.keys(DASHBOARDS);
    
    switch (role) {
        case ROLES.OPERATIONS:
			return dashboards.filter((dashboard) => DASHBOARDS[dashboard as keyof typeof DASHBOARDS].name != 'Main Update');
        case ROLES.LEADERSHIP:
            return dashboards;
        case ROLES.MEDICAL:
            return dashboards;
        case ROLES.ADMIN:
            return dashboards;
		case ROLES.LOCALHOST: 
			return dashboards;
        default:
            return dashboards;
    }
}

const get_default_dashboard = (role: string) => {
	switch (role) {
		case ROLES.OPERATIONS:
			return 'sandbox_patient_profiles';
		case ROLES.LEADERSHIP:
			return 'sandbox_main_update';
		case ROLES.MEDICAL:
			return 'sandbox_main_update';
		case ROLES.ADMIN:
			return 'sandbox_main_update';
		default:
			return 'sandbox_main_update'; // change later??
	}
}

export { getAccessibleDashboards, get_default_dashboard };